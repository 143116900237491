.main--header{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding: 0 7%;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10000;
    background: #fff;
    .header-navigation{
        display: flex;
        align-items: center;
        list-style: none;
    }
    .logo{
        width: 9rem;
    }
    .navigation{
        margin-left: 2rem;
        cursor: pointer;
        font-weight: 600;
    }
    .hamburger{
        display: none;
    }
}
@media only screen and (max-width: 768px) {
    .main--header{
        padding: 0 1rem;
        height: 4rem;
        .logo{
            width: 4rem;
        }
        .header-navigation{
            position: absolute;
            background: #2CA548;
            flex-direction: column;
            height: calc(100vh - 4rem);
            color: #fff;
            top: 4rem;
            right: 0;
            padding: 1rem;
            justify-content: center;
            width: 20rem;
            transform: translateX(100%);
            transition: all 0.3s ease;
        }
        .navigation--active{
            transform: translateX(0);
            .navigation{
                &:nth-child(1){
                    animation: slideItems 0.3s ease 0.3s forwards;
                }
                &:nth-child(2){
                    animation: slideItems 0.3s ease 0.35s forwards;
                }
                &:nth-child(3){
                    animation: slideItems 0.3s ease 0.4s forwards;
                }
                &:nth-child(4){
                    animation: slideItems 0.3s ease 0.45s forwards;
                }
            }
        }
        .navigation{
            margin: 1rem 0;
            text-transform: uppercase;
            font-size: 1.1rem;
            opacity: 0;
            a{
                color: #fff;
            }
        }
        .nav-active{
            &::after{
                width: 100%;
                height: 3px;
                background: #000;
                content: '';
                
            }
        }
        .hamburger{
            display: block;
            cursor: pointer;
            position: relative;
            width: 30px;
            height: 22px;
            transition: all 0.3s ease;
            span{
                pointer-events: none;
                width: 100%;
                height: 4px;
                background: #2CA548;
                border-radius: 10px;
                display: block;
                position: absolute;
                left: 0;
                transition: all 0.3s ease;
                &:nth-child(1){
                    top: 0;
                }
                &:nth-child(2){
                    top: 9px;
                }
                &:nth-child(3){
                    top: 18px;
                }
            }
            
        }
        .hamburger-close{
            transform: translateY(-9px);
            span{
                &:nth-child(1){
                    transform: rotate(135deg);
                    top: 18px;
                }
                &:nth-child(2){
                    left: -30px;
                    opacity: 0;
                }
                &:nth-child(3){
                    transform: rotate(-135deg);
                    top: 18px;
                }
            }
        }
    }
}

@keyframes slideItems {
    from{
        transform: translateX(100%);
        opacity: 0;
    }to{
        transform: translateX(0);
        opacity: 1;
    }
}
@media only screen and (max-width: 500px) {
    .main--header{
        .header-navigation{
            width: 100%;
        }
    }
}