@import '/src/variables';

.contact-section{
    display: grid;
    grid-template-columns: 30rem auto;
    grid-template-areas: 'get-touch form';
    grid-gap: 2rem;
    padding: 2rem 7%;
    .get-in-touch--wrapper{
        grid-area: get-touch;
        color: #fff;
        background-size: cover;
        background-position: center;
        border-radius: 10px;
        padding: 4rem 2rem;
    }
    .detail{
        display: flex;
        align-items: center;
        margin: 1.5rem 0;
    }
    .detail--icon{
        margin-right: 1rem;
    }
    .detail--title{
        font-size: 1rem;
        text-decoration: underline;
        margin-bottom: 0.5rem;
        font-family: $exo2;
    }
    a{
        color: #fff;
    }
    .detail--content{
        font-family: $exo2;
        cursor: pointer;
        color: #fff;
    }
    .contact--form{
        grid-area: form;
        width: 80%;
        justify-self: center;
    }
    .input-wrapper{
        display: flex;
        justify-content: space-between;
        align-self: center;
    }
    .input-wrapper .field{
        width: 49%;
    }
    .field{
        padding: 1rem;
        border-radius: 8px;
        border: 2px solid rgba(0,0,0,0.4);
        margin: 1rem 0;
        width: 100%;
        outline: none;
        resize: none;
        font-weight: 600;
        font-size: 18px;
    }
    .btn-wrapper{
        display: flex;
        justify-content: flex-end;
    }
    .btn-submit{
        border: none;
        background: #2CA548;
        color: #fff;
        font-weight: 600;
        font-family: $exo;
        padding: 0.5rem 2rem;
        font-size: 1.2rem;
        border-radius: 3px;
        cursor: pointer;
        text-transform: uppercase;
    }
}
@media only screen and (max-width:1300px) {
    .contact-section{
        grid-template-columns: 25rem auto;
        .contact--form{
            width: 100%;
        }
    }
}
@media only screen and (max-width:1200px) {
    .contact-section{
        grid-template-columns: 1fr;
        grid-template-areas: 'get-touch''form';
    }
}
@media only screen and (max-width: 500px) {
    .contact-section{
        padding: 1rem;
        .get-in-touch--wrapper{
            padding: 2rem 1rem;
        }
        .detail--icon{
            transform: scale(0.7);
        }
        .detail--content{
            font-size: 14px;
        }
        .field{
            font-size: 14px;
            padding: 0.5rem;
            border: 1px solid rgba(0,0,0,0.2);
            font-weight: 500;
            border-radius: 6px;
            margin: 0.5rem 0;
        }
        .btn-submit{
            font-size: 15px;
        }
    }
}