@import './variables';
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $roboto;
}
html{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.section--title{
  font-family: $abril;
  font-size: 3rem;
  text-align: center;
  text-transform: uppercase;
}
a{
  text-decoration: none;
  color: #000;
}
@media only screen and (max-width:768px) {
  .section--title{
    font-size: 2rem;
  }
}
@media only screen and (max-width:500px) {
  .section--title{
    font-size: 1.5rem;
  }
}