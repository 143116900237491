.privacy-policy-container{
    padding: 7rem 3rem;
    h1,h2,h3{
        margin: 1rem 0;
    }
    ul{
        margin-left: 1rem;
    }
}
@media only screen and (max-width:600px) {
    .privacy-policy-container{
        padding: 5rem 1rem;
        h1{
            font-size: 1.5rem;
        }
        h2,h3{
            font-size: 1.3rem;
        }
        p{
            font-size: 0.9rem;
        }
    }
}