@import '/src/variables';
.banner-item{
    width: calc(100% / 12);
    position: relative;
    text-transform: uppercase;
    color: #fff;
    height: 100%;
    z-index: 1000;
    .banner--image{
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-user-drag: none;
        user-drag: none;
        -webkit-touch-callout: none;
    }
    .corporate{
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        font-family: $abril;
        z-index: 2;
    }
    .banner-content-wrapper{
        height: 100%;
        background: rgba(24, 24, 24, 0.8);;
        position: absolute;
        left: 0;
        top: 0;
        width: 26rem;
        padding: 0 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .banner--category{
        font-size: 3.3rem;
        font-weight: 600;
    }
    .banner--description{
        font-size: 1rem;
        margin: 1rem 0;
        line-height: 25px;
        letter-spacing: 1px;
    }
    .btn-view{
        font-size: 1rem;
        width: max-content;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        background: #000;
        border: none;
        color: #fff;
        text-transform: uppercase;
        cursor: pointer;
    }
}

@media only screen and (max-width:768px) {
    .banner-item{
        height: 100%;
        border-radius: 0;
        .banner--image{
            border-radius: 0;
        }
        .banner-content-wrapper{
            width: 18rem;
            padding: 0 2rem;
            border-radius: 0;
        }
        .banner--category{
            font-size: 2rem;
            font-weight: 600;
        }
        .banner--description{
            font-size: 0.9rem;
        }
        .btn-view{
            font-size: 1rem;
        }
    }
    
}
@media only screen and (max-width: 550px) {
    .banner-item{
        .banner--category{
            font-size: 2rem;
            right: 1rem;
        }
        .corporate{
            font-size: 14px;
            bottom: 5rem;
            right: 1rem;
        }
        .banner-content-wrapper{
            width: 10rem;
            padding: 0 1rem
        }
        .banner--category{
            font-size: 1.2rem;
            font-weight: 600;
        }
        .banner--description{
            font-size: 0.8rem;
        }
        .btn-view{
            font-size: 0.9rem;
        }
    }
}