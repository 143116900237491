.gallery{
    padding: 8rem 16%;
    .gallery-header{
        padding-bottom: 4rem;
        text-align: center;
    }
    .heading{
        font-size: 3rem;
        margin-bottom: 2rem;
        text-transform: uppercase;
        font-size: 600;
    }
    .description{
        max-width: 50rem;
        font-size: 1.2rem;
        margin: auto;
    }
    .gallery-items-wrapper{
        display: grid;
        grid-template-columns: repeat(5,1fr);
        grid-auto-rows: calc(100vw / 6);
        grid-gap: 1rem;
    }
    .gallery-item{
        border-radius: 10px;
        object-fit: cover;
        transition: all 0.3s ease;
    }
    .card-wrapper{
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 10px;
        cursor: pointer;
        &:hover .gallery-item{
            transform: scale(1.1);
        }
    }
}
@media only screen and (max-width:1300px) {
    .gallery{
        padding: 7rem 5%;
        .gallery-items-wrapper{
            grid-auto-rows: calc(100vw /5);
        }
    }   
}

@media only screen and (max-width:1000px) {
    .gallery{
        .heading{
            font-size: 2rem;
            margin-bottom: 1rem;
        }
        .description{
            font-size: 1rem;
        }
        .gallery-items-wrapper{
            grid-template-columns: repeat(3,1fr);
            grid-auto-rows: calc(100vw / 3);
        }
    }
}
@media only screen and (max-width: 420px) {
    .gallery{
        padding-top: 4rem;
        .heading{
            font-size: 1.5rem;
        }
        .description{
            font-size: 0.9rem;
        }
        .gallery-header{
            padding-bottom: 2rem;
        }
        .gallery-items-wrapper{
            grid-template-columns: repeat(2,1fr);
            grid-auto-rows: calc(100vw / 2);
        }
    }
}